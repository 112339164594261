/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDemoRequest = /* GraphQL */ `
  mutation CreateDemoRequest(
    $input: CreateDemoRequestInput!
    $condition: ModelDemoRequestConditionInput
  ) {
    createDemoRequest(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDemoRequest = /* GraphQL */ `
  mutation UpdateDemoRequest(
    $input: UpdateDemoRequestInput!
    $condition: ModelDemoRequestConditionInput
  ) {
    updateDemoRequest(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDemoRequest = /* GraphQL */ `
  mutation DeleteDemoRequest(
    $input: DeleteDemoRequestInput!
    $condition: ModelDemoRequestConditionInput
  ) {
    deleteDemoRequest(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      subjectPropertyAddress
      subjectPropertyAddress2
      subjectPropertyCity
      subjectPropertyState
      subjectPropertyZip
      numberOfUnits
      legalDescription
      yearBuilt
      purposeOfProperty
      purposeOfLoan
      cons_yearLotAcquired
      cons_originalCost
      cons_AmountExistingLiens
      cons_presentVal
      cons_costOfImprovements
      cons_total
      refinance_yearAcquired
      refinance_originalCost
      refinance_amountExistingLiens
      refinance_purpose
      refinance_describeImprovements
      refinance_cost
      refinance_improvementsMade
      titleName
      titleManner
      sourceOfFinancing
      estateToBeHeld
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      subjectPropertyAddress
      subjectPropertyAddress2
      subjectPropertyCity
      subjectPropertyState
      subjectPropertyZip
      numberOfUnits
      legalDescription
      yearBuilt
      purposeOfProperty
      purposeOfLoan
      cons_yearLotAcquired
      cons_originalCost
      cons_AmountExistingLiens
      cons_presentVal
      cons_costOfImprovements
      cons_total
      refinance_yearAcquired
      refinance_originalCost
      refinance_amountExistingLiens
      refinance_purpose
      refinance_describeImprovements
      refinance_cost
      refinance_improvementsMade
      titleName
      titleManner
      sourceOfFinancing
      estateToBeHeld
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      subjectPropertyAddress
      subjectPropertyAddress2
      subjectPropertyCity
      subjectPropertyState
      subjectPropertyZip
      numberOfUnits
      legalDescription
      yearBuilt
      purposeOfProperty
      purposeOfLoan
      cons_yearLotAcquired
      cons_originalCost
      cons_AmountExistingLiens
      cons_presentVal
      cons_costOfImprovements
      cons_total
      refinance_yearAcquired
      refinance_originalCost
      refinance_amountExistingLiens
      refinance_purpose
      refinance_describeImprovements
      refinance_cost
      refinance_improvementsMade
      titleName
      titleManner
      sourceOfFinancing
      estateToBeHeld
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCoborrower = /* GraphQL */ `
  mutation CreateCoborrower(
    $input: CreateCoborrowerInput!
    $condition: ModelCoborrowerConditionInput
  ) {
    createCoborrower(input: $input, condition: $condition) {
      firstname
      lastname
      address1
      address2
      city
      resState
      zip
      maritalStatus
      numberOfDependents
      birthdate
      ssn
      formerAddress1
      formerAddress2
      formerCity
      formerState
      formerZipcode
      presentAddressOwnership
      formerAddressOwnership
      mailingAddress1
      mailingAddress2
      mailingAddressCity
      mailingAddressState
      mailingAddressZip
      agesOfDependents
      yrsInSchool
      phone
      noOfYrsAtPresentResidence
      noOfYrsAtFormerResidence
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCoborrower = /* GraphQL */ `
  mutation UpdateCoborrower(
    $input: UpdateCoborrowerInput!
    $condition: ModelCoborrowerConditionInput
  ) {
    updateCoborrower(input: $input, condition: $condition) {
      firstname
      lastname
      address1
      address2
      city
      resState
      zip
      maritalStatus
      numberOfDependents
      birthdate
      ssn
      formerAddress1
      formerAddress2
      formerCity
      formerState
      formerZipcode
      presentAddressOwnership
      formerAddressOwnership
      mailingAddress1
      mailingAddress2
      mailingAddressCity
      mailingAddressState
      mailingAddressZip
      agesOfDependents
      yrsInSchool
      phone
      noOfYrsAtPresentResidence
      noOfYrsAtFormerResidence
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCoborrower = /* GraphQL */ `
  mutation DeleteCoborrower(
    $input: DeleteCoborrowerInput!
    $condition: ModelCoborrowerConditionInput
  ) {
    deleteCoborrower(input: $input, condition: $condition) {
      firstname
      lastname
      address1
      address2
      city
      resState
      zip
      maritalStatus
      numberOfDependents
      birthdate
      ssn
      formerAddress1
      formerAddress2
      formerCity
      formerState
      formerZipcode
      presentAddressOwnership
      formerAddressOwnership
      mailingAddress1
      mailingAddress2
      mailingAddressCity
      mailingAddressState
      mailingAddressZip
      agesOfDependents
      yrsInSchool
      phone
      noOfYrsAtPresentResidence
      noOfYrsAtFormerResidence
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDeclarations = /* GraphQL */ `
  mutation CreateDeclarations(
    $input: CreateDeclarationsInput!
    $condition: ModelDeclarationsConditionInput
  ) {
    createDeclarations(input: $input, condition: $condition) {
      borrowerDelinquent
      coborrowerDelinquent
      borrowerObligatedMaintenance
      coborrowerObligatedMaintenance
      borrowerDownpaymentBorrowed
      coborrowerDownpaymentBorrowed
      borrowerNote
      coborrowerNote
      borrowerCitizen
      coborrowerCitizen
      borrowerResidentAlien
      coborrowerResidentAlien
      borrowerPrimaryResidence
      coborrowerPrimaryResidence
      borrowerOwnership
      coborrowerOwnership
      borrowerPropertyOwned
      coborrowerPropertyOwned
      borrowerTitleHeld
      coborrowerTitleHeld
      borrowerOutstandingJudgments
      coborrowerOutstandingJudgments
      borrowerBankrupt
      coborrowerBankrupt
      borrowerForeclosed
      coborrowerForeclosed
      borrowerLawsuit
      coborrowerLawsuit
      borrowerObligatedLoan
      coborrowerObligatedLoan
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDeclarations = /* GraphQL */ `
  mutation UpdateDeclarations(
    $input: UpdateDeclarationsInput!
    $condition: ModelDeclarationsConditionInput
  ) {
    updateDeclarations(input: $input, condition: $condition) {
      borrowerDelinquent
      coborrowerDelinquent
      borrowerObligatedMaintenance
      coborrowerObligatedMaintenance
      borrowerDownpaymentBorrowed
      coborrowerDownpaymentBorrowed
      borrowerNote
      coborrowerNote
      borrowerCitizen
      coborrowerCitizen
      borrowerResidentAlien
      coborrowerResidentAlien
      borrowerPrimaryResidence
      coborrowerPrimaryResidence
      borrowerOwnership
      coborrowerOwnership
      borrowerPropertyOwned
      coborrowerPropertyOwned
      borrowerTitleHeld
      coborrowerTitleHeld
      borrowerOutstandingJudgments
      coborrowerOutstandingJudgments
      borrowerBankrupt
      coborrowerBankrupt
      borrowerForeclosed
      coborrowerForeclosed
      borrowerLawsuit
      coborrowerLawsuit
      borrowerObligatedLoan
      coborrowerObligatedLoan
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDeclarations = /* GraphQL */ `
  mutation DeleteDeclarations(
    $input: DeleteDeclarationsInput!
    $condition: ModelDeclarationsConditionInput
  ) {
    deleteDeclarations(input: $input, condition: $condition) {
      borrowerDelinquent
      coborrowerDelinquent
      borrowerObligatedMaintenance
      coborrowerObligatedMaintenance
      borrowerDownpaymentBorrowed
      coborrowerDownpaymentBorrowed
      borrowerNote
      coborrowerNote
      borrowerCitizen
      coborrowerCitizen
      borrowerResidentAlien
      coborrowerResidentAlien
      borrowerPrimaryResidence
      coborrowerPrimaryResidence
      borrowerOwnership
      coborrowerOwnership
      borrowerPropertyOwned
      coborrowerPropertyOwned
      borrowerTitleHeld
      coborrowerTitleHeld
      borrowerOutstandingJudgments
      coborrowerOutstandingJudgments
      borrowerBankrupt
      coborrowerBankrupt
      borrowerForeclosed
      coborrowerForeclosed
      borrowerLawsuit
      coborrowerLawsuit
      borrowerObligatedLoan
      coborrowerObligatedLoan
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDetailsOfTransaction = /* GraphQL */ `
  mutation CreateDetailsOfTransaction(
    $input: CreateDetailsOfTransactionInput!
    $condition: ModelDetailsOfTransactionConditionInput
  ) {
    createDetailsOfTransaction(input: $input, condition: $condition) {
      purchasePrice
      repairs
      land
      refinance
      estPrepaid
      estClosing
      fundingFee
      discounts
      totalCosts
      subordinateFinancing
      borrowersClosing
      otherCredits
      loanAmount
      fundingFeeFinanced
      loanAmountAdd
      cash
      otherCreditsAmount
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDetailsOfTransaction = /* GraphQL */ `
  mutation UpdateDetailsOfTransaction(
    $input: UpdateDetailsOfTransactionInput!
    $condition: ModelDetailsOfTransactionConditionInput
  ) {
    updateDetailsOfTransaction(input: $input, condition: $condition) {
      purchasePrice
      repairs
      land
      refinance
      estPrepaid
      estClosing
      fundingFee
      discounts
      totalCosts
      subordinateFinancing
      borrowersClosing
      otherCredits
      loanAmount
      fundingFeeFinanced
      loanAmountAdd
      cash
      otherCreditsAmount
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDetailsOfTransaction = /* GraphQL */ `
  mutation DeleteDetailsOfTransaction(
    $input: DeleteDetailsOfTransactionInput!
    $condition: ModelDetailsOfTransactionConditionInput
  ) {
    deleteDetailsOfTransaction(input: $input, condition: $condition) {
      purchasePrice
      repairs
      land
      refinance
      estPrepaid
      estClosing
      fundingFee
      discounts
      totalCosts
      subordinateFinancing
      borrowersClosing
      otherCredits
      loanAmount
      fundingFeeFinanced
      loanAmountAdd
      cash
      otherCreditsAmount
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRealEstateInfo = /* GraphQL */ `
  mutation CreateRealEstateInfo(
    $input: CreateRealEstateInfoInput!
    $condition: ModelRealEstateInfoConditionInput
  ) {
    createRealEstateInfo(input: $input, condition: $condition) {
      propertyAddress
      propertyStatus
      typeOfProperty
      presentMarketValue
      amountOfMortgageAndLiens
      grossRentalIncome
      mortgagePayments
      miscPayments
      netRentalIncome
      propertyAddress2
      propertyStatus2
      typeOfProperty2
      presentMarketValue2
      amountOfMortgageAndLiens2
      grossRentalIncome2
      mortgagePayments2
      miscPayments2
      netRentalIncome2
      propertyAddress3
      propertyStatus3
      typeOfProperty3
      presentMarketValue3
      amountOfMortgageAndLiens3
      grossRentalIncome3
      mortgagePayments3
      miscPayments3
      netRentalIncome3
      totalMarketValue
      totalMortgages
      totalGrossRentalIncome
      totalMortgagePayments
      totalMisc
      totalNetRentalIncome
      altName
      creditorName
      accNumber
      altName2
      creditorName2
      accNumber2
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRealEstateInfo = /* GraphQL */ `
  mutation UpdateRealEstateInfo(
    $input: UpdateRealEstateInfoInput!
    $condition: ModelRealEstateInfoConditionInput
  ) {
    updateRealEstateInfo(input: $input, condition: $condition) {
      propertyAddress
      propertyStatus
      typeOfProperty
      presentMarketValue
      amountOfMortgageAndLiens
      grossRentalIncome
      mortgagePayments
      miscPayments
      netRentalIncome
      propertyAddress2
      propertyStatus2
      typeOfProperty2
      presentMarketValue2
      amountOfMortgageAndLiens2
      grossRentalIncome2
      mortgagePayments2
      miscPayments2
      netRentalIncome2
      propertyAddress3
      propertyStatus3
      typeOfProperty3
      presentMarketValue3
      amountOfMortgageAndLiens3
      grossRentalIncome3
      mortgagePayments3
      miscPayments3
      netRentalIncome3
      totalMarketValue
      totalMortgages
      totalGrossRentalIncome
      totalMortgagePayments
      totalMisc
      totalNetRentalIncome
      altName
      creditorName
      accNumber
      altName2
      creditorName2
      accNumber2
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRealEstateInfo = /* GraphQL */ `
  mutation DeleteRealEstateInfo(
    $input: DeleteRealEstateInfoInput!
    $condition: ModelRealEstateInfoConditionInput
  ) {
    deleteRealEstateInfo(input: $input, condition: $condition) {
      propertyAddress
      propertyStatus
      typeOfProperty
      presentMarketValue
      amountOfMortgageAndLiens
      grossRentalIncome
      mortgagePayments
      miscPayments
      netRentalIncome
      propertyAddress2
      propertyStatus2
      typeOfProperty2
      presentMarketValue2
      amountOfMortgageAndLiens2
      grossRentalIncome2
      mortgagePayments2
      miscPayments2
      netRentalIncome2
      propertyAddress3
      propertyStatus3
      typeOfProperty3
      presentMarketValue3
      amountOfMortgageAndLiens3
      grossRentalIncome3
      mortgagePayments3
      miscPayments3
      netRentalIncome3
      totalMarketValue
      totalMortgages
      totalGrossRentalIncome
      totalMortgagePayments
      totalMisc
      totalNetRentalIncome
      altName
      creditorName
      accNumber
      altName2
      creditorName2
      accNumber2
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRaceInfo = /* GraphQL */ `
  mutation CreateRaceInfo(
    $input: CreateRaceInfoInput!
    $condition: ModelRaceInfoConditionInput
  ) {
    createRaceInfo(input: $input, condition: $condition) {
      borrowerDoNotWish
      borrowerHispanic
      borrowerEthnicity
      borrowerSex
      coborrowerDoNotWish
      coborrowerHispanic
      coborrowerEthnicity
      coborrowerSex
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRaceInfo = /* GraphQL */ `
  mutation UpdateRaceInfo(
    $input: UpdateRaceInfoInput!
    $condition: ModelRaceInfoConditionInput
  ) {
    updateRaceInfo(input: $input, condition: $condition) {
      borrowerDoNotWish
      borrowerHispanic
      borrowerEthnicity
      borrowerSex
      coborrowerDoNotWish
      coborrowerHispanic
      coborrowerEthnicity
      coborrowerSex
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRaceInfo = /* GraphQL */ `
  mutation DeleteRaceInfo(
    $input: DeleteRaceInfoInput!
    $condition: ModelRaceInfoConditionInput
  ) {
    deleteRaceInfo(input: $input, condition: $condition) {
      borrowerDoNotWish
      borrowerHispanic
      borrowerEthnicity
      borrowerSex
      coborrowerDoNotWish
      coborrowerHispanic
      coborrowerEthnicity
      coborrowerSex
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLiabilitiesInfo = /* GraphQL */ `
  mutation CreateLiabilitiesInfo(
    $input: CreateLiabilitiesInfoInput!
    $condition: ModelLiabilitiesInfoConditionInput
  ) {
    createLiabilitiesInfo(input: $input, condition: $condition) {
      nameAndAddressOfCompany
      accountNo
      payments
      unpaidBalance
      nameAndAddressOfCompany2
      accountNo2
      payments2
      unpaidBalance2
      nameAndAddressOfCompany3
      accountNo3
      payments3
      unpaidBalance3
      nameAndAddressOfCompany4
      accountNo4
      payments4
      unpaidBalance4
      nameAndAddressOfCompany5
      accountNo5
      payments5
      unpaidBalance5
      nameAndAddressOfCompany6
      accountNo6
      payments6
      unpaidBalance6
      maintenancePaymentsOwed
      maintenancePaymentsAmount
      jobRelatedExpenses
      jobRelatedExpensesAmount
      totalMonthlyPayments
      totalLiabilities
      netWorth
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLiabilitiesInfo = /* GraphQL */ `
  mutation UpdateLiabilitiesInfo(
    $input: UpdateLiabilitiesInfoInput!
    $condition: ModelLiabilitiesInfoConditionInput
  ) {
    updateLiabilitiesInfo(input: $input, condition: $condition) {
      nameAndAddressOfCompany
      accountNo
      payments
      unpaidBalance
      nameAndAddressOfCompany2
      accountNo2
      payments2
      unpaidBalance2
      nameAndAddressOfCompany3
      accountNo3
      payments3
      unpaidBalance3
      nameAndAddressOfCompany4
      accountNo4
      payments4
      unpaidBalance4
      nameAndAddressOfCompany5
      accountNo5
      payments5
      unpaidBalance5
      nameAndAddressOfCompany6
      accountNo6
      payments6
      unpaidBalance6
      maintenancePaymentsOwed
      maintenancePaymentsAmount
      jobRelatedExpenses
      jobRelatedExpensesAmount
      totalMonthlyPayments
      totalLiabilities
      netWorth
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLiabilitiesInfo = /* GraphQL */ `
  mutation DeleteLiabilitiesInfo(
    $input: DeleteLiabilitiesInfoInput!
    $condition: ModelLiabilitiesInfoConditionInput
  ) {
    deleteLiabilitiesInfo(input: $input, condition: $condition) {
      nameAndAddressOfCompany
      accountNo
      payments
      unpaidBalance
      nameAndAddressOfCompany2
      accountNo2
      payments2
      unpaidBalance2
      nameAndAddressOfCompany3
      accountNo3
      payments3
      unpaidBalance3
      nameAndAddressOfCompany4
      accountNo4
      payments4
      unpaidBalance4
      nameAndAddressOfCompany5
      accountNo5
      payments5
      unpaidBalance5
      nameAndAddressOfCompany6
      accountNo6
      payments6
      unpaidBalance6
      maintenancePaymentsOwed
      maintenancePaymentsAmount
      jobRelatedExpenses
      jobRelatedExpensesAmount
      totalMonthlyPayments
      totalLiabilities
      netWorth
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAssetsInfo = /* GraphQL */ `
  mutation CreateAssetsInfo(
    $input: CreateAssetsInfoInput!
    $condition: ModelAssetsInfoConditionInput
  ) {
    createAssetsInfo(input: $input, condition: $condition) {
      cashDeposit
      cashOrMarketVal
      nameAddressOfBank
      accountNumber
      amount
      nameAddressOfBank2
      accountNumber2
      amount2
      nameAddressOfBank3
      accountNumber3
      amount3
      nameAddressOfBank4
      accountNumber4
      amount4
      stockandBondsCompany
      stockAmount
      lifeInsuranceFaceAmount
      insuranceAmount
      subtotalAssets
      realEstateAmount
      retirementFund
      netWorthOfBusiness
      automobilesMakeAndYear
      automobilesAmount
      otherAssets
      otherAssetsAmount
      totalAssets
      completedJointly
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAssetsInfo = /* GraphQL */ `
  mutation UpdateAssetsInfo(
    $input: UpdateAssetsInfoInput!
    $condition: ModelAssetsInfoConditionInput
  ) {
    updateAssetsInfo(input: $input, condition: $condition) {
      cashDeposit
      cashOrMarketVal
      nameAddressOfBank
      accountNumber
      amount
      nameAddressOfBank2
      accountNumber2
      amount2
      nameAddressOfBank3
      accountNumber3
      amount3
      nameAddressOfBank4
      accountNumber4
      amount4
      stockandBondsCompany
      stockAmount
      lifeInsuranceFaceAmount
      insuranceAmount
      subtotalAssets
      realEstateAmount
      retirementFund
      netWorthOfBusiness
      automobilesMakeAndYear
      automobilesAmount
      otherAssets
      otherAssetsAmount
      totalAssets
      completedJointly
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAssetsInfo = /* GraphQL */ `
  mutation DeleteAssetsInfo(
    $input: DeleteAssetsInfoInput!
    $condition: ModelAssetsInfoConditionInput
  ) {
    deleteAssetsInfo(input: $input, condition: $condition) {
      cashDeposit
      cashOrMarketVal
      nameAddressOfBank
      accountNumber
      amount
      nameAddressOfBank2
      accountNumber2
      amount2
      nameAddressOfBank3
      accountNumber3
      amount3
      nameAddressOfBank4
      accountNumber4
      amount4
      stockandBondsCompany
      stockAmount
      lifeInsuranceFaceAmount
      insuranceAmount
      subtotalAssets
      realEstateAmount
      retirementFund
      netWorthOfBusiness
      automobilesMakeAndYear
      automobilesAmount
      otherAssets
      otherAssetsAmount
      totalAssets
      completedJointly
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCoborrowerEmploymentInfo = /* GraphQL */ `
  mutation CreateCoborrowerEmploymentInfo(
    $input: CreateCoborrowerEmploymentInfoInput!
    $condition: ModelCoborrowerEmploymentInfoConditionInput
  ) {
    createCoborrowerEmploymentInfo(input: $input, condition: $condition) {
      employer
      selfEmployed
      jobPosition
      businessPhone
      employer2
      selfEmployed2
      jobPosition2
      businessPhone2
      employer3
      selfEmployed3
      jobPosition3
      businessPhone3
      yearsOnJob
      yearsInThisProf
      employDates2
      employDates3
      income2
      income3
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCoborrowerEmploymentInfo = /* GraphQL */ `
  mutation UpdateCoborrowerEmploymentInfo(
    $input: UpdateCoborrowerEmploymentInfoInput!
    $condition: ModelCoborrowerEmploymentInfoConditionInput
  ) {
    updateCoborrowerEmploymentInfo(input: $input, condition: $condition) {
      employer
      selfEmployed
      jobPosition
      businessPhone
      employer2
      selfEmployed2
      jobPosition2
      businessPhone2
      employer3
      selfEmployed3
      jobPosition3
      businessPhone3
      yearsOnJob
      yearsInThisProf
      employDates2
      employDates3
      income2
      income3
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCoborrowerEmploymentInfo = /* GraphQL */ `
  mutation DeleteCoborrowerEmploymentInfo(
    $input: DeleteCoborrowerEmploymentInfoInput!
    $condition: ModelCoborrowerEmploymentInfoConditionInput
  ) {
    deleteCoborrowerEmploymentInfo(input: $input, condition: $condition) {
      employer
      selfEmployed
      jobPosition
      businessPhone
      employer2
      selfEmployed2
      jobPosition2
      businessPhone2
      employer3
      selfEmployed3
      jobPosition3
      businessPhone3
      yearsOnJob
      yearsInThisProf
      employDates2
      employDates3
      income2
      income3
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCoborrowerInfo = /* GraphQL */ `
  mutation CreateCoborrowerInfo(
    $input: CreateCoborrowerInfoInput!
    $condition: ModelCoborrowerInfoConditionInput
  ) {
    createCoborrowerInfo(input: $input, condition: $condition) {
      firstname
      lastname
      address1
      address2
      city
      resState
      zip
      maritalStatus
      numberOfDependents
      birthdate
      ssn
      formerAddress1
      formerAddress2
      formerCity
      formerState
      formerZipcode
      presentAddressOwnership
      formerAddressOwnership
      mailingAddress1
      mailingAddress2
      mailingAddressCity
      mailingAddressState
      mailingAddressZip
      agesOfDependents
      yrsInSchool
      phone
      noOfYrsAtPresentResidence
      noOfYrsAtFormerResidence
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCoborrowerInfo = /* GraphQL */ `
  mutation UpdateCoborrowerInfo(
    $input: UpdateCoborrowerInfoInput!
    $condition: ModelCoborrowerInfoConditionInput
  ) {
    updateCoborrowerInfo(input: $input, condition: $condition) {
      firstname
      lastname
      address1
      address2
      city
      resState
      zip
      maritalStatus
      numberOfDependents
      birthdate
      ssn
      formerAddress1
      formerAddress2
      formerCity
      formerState
      formerZipcode
      presentAddressOwnership
      formerAddressOwnership
      mailingAddress1
      mailingAddress2
      mailingAddressCity
      mailingAddressState
      mailingAddressZip
      agesOfDependents
      yrsInSchool
      phone
      noOfYrsAtPresentResidence
      noOfYrsAtFormerResidence
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCoborrowerInfo = /* GraphQL */ `
  mutation DeleteCoborrowerInfo(
    $input: DeleteCoborrowerInfoInput!
    $condition: ModelCoborrowerInfoConditionInput
  ) {
    deleteCoborrowerInfo(input: $input, condition: $condition) {
      firstname
      lastname
      address1
      address2
      city
      resState
      zip
      maritalStatus
      numberOfDependents
      birthdate
      ssn
      formerAddress1
      formerAddress2
      formerCity
      formerState
      formerZipcode
      presentAddressOwnership
      formerAddressOwnership
      mailingAddress1
      mailingAddress2
      mailingAddressCity
      mailingAddressState
      mailingAddressZip
      agesOfDependents
      yrsInSchool
      phone
      noOfYrsAtPresentResidence
      noOfYrsAtFormerResidence
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createIncomeAndExpenseInfo = /* GraphQL */ `
  mutation CreateIncomeAndExpenseInfo(
    $input: CreateIncomeAndExpenseInfoInput!
    $condition: ModelIncomeAndExpenseInfoConditionInput
  ) {
    createIncomeAndExpenseInfo(input: $input, condition: $condition) {
      borrower_baseIncome
      borrower_overtime
      borrower_bonus
      borrower_commissions
      borrower_dividends
      borrower_netRentalIncome
      borrower_other
      borrower_totalIncome
      coborrower_baseIncome
      coborrower_overtime
      coborrower_bonus
      coborrower_commissions
      coborrower_dividends
      coborrower_netRentalIncome
      coborrower_other
      coborrower_totalIncome
      family_total
      total_baseIncome
      total_overtime
      total_bonus
      total_commissions
      total_dividends
      total_rentalIncome
      total_otherIncome
      grand_total_income
      present_rent
      present_mortgage1
      present_financing
      proposed_mortgage1
      proposed_financing
      present_hazard_insurance
      proposed_hazard_insurance
      present_real_estate_taxes
      proposed_real_estate_taxes
      present_mortgage_insurance
      proposed_mortgage_insurance
      present_hoa_dues
      proposed_hoa_dues
      present_other_expenses
      proposed_other_expenses
      total_combined_expenses
      proposed_total_combined_expenses
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateIncomeAndExpenseInfo = /* GraphQL */ `
  mutation UpdateIncomeAndExpenseInfo(
    $input: UpdateIncomeAndExpenseInfoInput!
    $condition: ModelIncomeAndExpenseInfoConditionInput
  ) {
    updateIncomeAndExpenseInfo(input: $input, condition: $condition) {
      borrower_baseIncome
      borrower_overtime
      borrower_bonus
      borrower_commissions
      borrower_dividends
      borrower_netRentalIncome
      borrower_other
      borrower_totalIncome
      coborrower_baseIncome
      coborrower_overtime
      coborrower_bonus
      coborrower_commissions
      coborrower_dividends
      coborrower_netRentalIncome
      coborrower_other
      coborrower_totalIncome
      family_total
      total_baseIncome
      total_overtime
      total_bonus
      total_commissions
      total_dividends
      total_rentalIncome
      total_otherIncome
      grand_total_income
      present_rent
      present_mortgage1
      present_financing
      proposed_mortgage1
      proposed_financing
      present_hazard_insurance
      proposed_hazard_insurance
      present_real_estate_taxes
      proposed_real_estate_taxes
      present_mortgage_insurance
      proposed_mortgage_insurance
      present_hoa_dues
      proposed_hoa_dues
      present_other_expenses
      proposed_other_expenses
      total_combined_expenses
      proposed_total_combined_expenses
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteIncomeAndExpenseInfo = /* GraphQL */ `
  mutation DeleteIncomeAndExpenseInfo(
    $input: DeleteIncomeAndExpenseInfoInput!
    $condition: ModelIncomeAndExpenseInfoConditionInput
  ) {
    deleteIncomeAndExpenseInfo(input: $input, condition: $condition) {
      borrower_baseIncome
      borrower_overtime
      borrower_bonus
      borrower_commissions
      borrower_dividends
      borrower_netRentalIncome
      borrower_other
      borrower_totalIncome
      coborrower_baseIncome
      coborrower_overtime
      coborrower_bonus
      coborrower_commissions
      coborrower_dividends
      coborrower_netRentalIncome
      coborrower_other
      coborrower_totalIncome
      family_total
      total_baseIncome
      total_overtime
      total_bonus
      total_commissions
      total_dividends
      total_rentalIncome
      total_otherIncome
      grand_total_income
      present_rent
      present_mortgage1
      present_financing
      proposed_mortgage1
      proposed_financing
      present_hazard_insurance
      proposed_hazard_insurance
      present_real_estate_taxes
      proposed_real_estate_taxes
      present_mortgage_insurance
      proposed_mortgage_insurance
      present_hoa_dues
      proposed_hoa_dues
      present_other_expenses
      proposed_other_expenses
      total_combined_expenses
      proposed_total_combined_expenses
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEmploymentInfo = /* GraphQL */ `
  mutation CreateEmploymentInfo(
    $input: CreateEmploymentInfoInput!
    $condition: ModelEmploymentInfoConditionInput
  ) {
    createEmploymentInfo(input: $input, condition: $condition) {
      employer
      selfEmployed
      jobPosition
      businessPhone
      employer2
      employer3
      selfEmployed2
      selfEmployed3
      jobPosition2
      jobPosition3
      businessPhone2
      businessPhone3
      yearsOnJob
      yearsInThisProf
      employDates2
      employDates3
      income2
      income3
      completedJointly
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmploymentInfo = /* GraphQL */ `
  mutation UpdateEmploymentInfo(
    $input: UpdateEmploymentInfoInput!
    $condition: ModelEmploymentInfoConditionInput
  ) {
    updateEmploymentInfo(input: $input, condition: $condition) {
      employer
      selfEmployed
      jobPosition
      businessPhone
      employer2
      employer3
      selfEmployed2
      selfEmployed3
      jobPosition2
      jobPosition3
      businessPhone2
      businessPhone3
      yearsOnJob
      yearsInThisProf
      employDates2
      employDates3
      income2
      income3
      completedJointly
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmploymentInfo = /* GraphQL */ `
  mutation DeleteEmploymentInfo(
    $input: DeleteEmploymentInfoInput!
    $condition: ModelEmploymentInfoConditionInput
  ) {
    deleteEmploymentInfo(input: $input, condition: $condition) {
      employer
      selfEmployed
      jobPosition
      businessPhone
      employer2
      employer3
      selfEmployed2
      selfEmployed3
      jobPosition2
      jobPosition3
      businessPhone2
      businessPhone3
      yearsOnJob
      yearsInThisProf
      employDates2
      employDates3
      income2
      income3
      completedJointly
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPropertyInfo = /* GraphQL */ `
  mutation CreatePropertyInfo(
    $input: CreatePropertyInfoInput!
    $condition: ModelPropertyInfoConditionInput
  ) {
    createPropertyInfo(input: $input, condition: $condition) {
      subjectPropertyAddress
      subjectPropertyAddress2
      subjectPropertyCity
      subjectPropertyState
      subjectPropertyZip
      numberOfUnits
      legalDescription
      yearBuilt
      purposeOfProperty
      purposeOfLoan
      cons_yearLotAcquired
      cons_originalCost
      cons_AmountExistingLiens
      cons_presentVal
      cons_costOfImprovements
      cons_total
      refinance_yearAcquired
      refinance_originalCost
      refinance_amountExistingLiens
      refinance_purpose
      refinance_describeImprovements
      refinance_cost
      refinance_improvementsMade
      titleName
      titleManner
      sourceOfFinancing
      estateToBeHeld
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePropertyInfo = /* GraphQL */ `
  mutation UpdatePropertyInfo(
    $input: UpdatePropertyInfoInput!
    $condition: ModelPropertyInfoConditionInput
  ) {
    updatePropertyInfo(input: $input, condition: $condition) {
      subjectPropertyAddress
      subjectPropertyAddress2
      subjectPropertyCity
      subjectPropertyState
      subjectPropertyZip
      numberOfUnits
      legalDescription
      yearBuilt
      purposeOfProperty
      purposeOfLoan
      cons_yearLotAcquired
      cons_originalCost
      cons_AmountExistingLiens
      cons_presentVal
      cons_costOfImprovements
      cons_total
      refinance_yearAcquired
      refinance_originalCost
      refinance_amountExistingLiens
      refinance_purpose
      refinance_describeImprovements
      refinance_cost
      refinance_improvementsMade
      titleName
      titleManner
      sourceOfFinancing
      estateToBeHeld
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePropertyInfo = /* GraphQL */ `
  mutation DeletePropertyInfo(
    $input: DeletePropertyInfoInput!
    $condition: ModelPropertyInfoConditionInput
  ) {
    deletePropertyInfo(input: $input, condition: $condition) {
      subjectPropertyAddress
      subjectPropertyAddress2
      subjectPropertyCity
      subjectPropertyState
      subjectPropertyZip
      numberOfUnits
      legalDescription
      yearBuilt
      purposeOfProperty
      purposeOfLoan
      cons_yearLotAcquired
      cons_originalCost
      cons_AmountExistingLiens
      cons_presentVal
      cons_costOfImprovements
      cons_total
      refinance_yearAcquired
      refinance_originalCost
      refinance_amountExistingLiens
      refinance_purpose
      refinance_describeImprovements
      refinance_cost
      refinance_improvementsMade
      titleName
      titleManner
      sourceOfFinancing
      estateToBeHeld
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLoanApplication = /* GraphQL */ `
  mutation CreateLoanApplication(
    $input: CreateLoanApplicationInput!
    $condition: ModelLoanApplicationConditionInput
  ) {
    createLoanApplication(input: $input, condition: $condition) {
      borrowerName
      coborrowerName
      mortgageType
      amount
      interestRate
      numberOfMonths
      amortizationType
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLoanApplication = /* GraphQL */ `
  mutation UpdateLoanApplication(
    $input: UpdateLoanApplicationInput!
    $condition: ModelLoanApplicationConditionInput
  ) {
    updateLoanApplication(input: $input, condition: $condition) {
      borrowerName
      coborrowerName
      mortgageType
      amount
      interestRate
      numberOfMonths
      amortizationType
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLoanApplication = /* GraphQL */ `
  mutation DeleteLoanApplication(
    $input: DeleteLoanApplicationInput!
    $condition: ModelLoanApplicationConditionInput
  ) {
    deleteLoanApplication(input: $input, condition: $condition) {
      borrowerName
      coborrowerName
      mortgageType
      amount
      interestRate
      numberOfMonths
      amortizationType
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBorrowerInfo = /* GraphQL */ `
  mutation CreateBorrowerInfo(
    $input: CreateBorrowerInfoInput!
    $condition: ModelBorrowerInfoConditionInput
  ) {
    createBorrowerInfo(input: $input, condition: $condition) {
      fullName
      address
      maritalStatus
      numberOfDependents
      birthdate
      ssn
      formerAddress
      phone
      presentAddressOwnership
      formerAddressOwnership
      mailingAddress
      agesOfDependents
      yrsInSchool
      noOfYrsAtPresentResidence
      noOfYrsAtFormerResidence
      email
      userToken
      clientUserId
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBorrowerInfo = /* GraphQL */ `
  mutation UpdateBorrowerInfo(
    $input: UpdateBorrowerInfoInput!
    $condition: ModelBorrowerInfoConditionInput
  ) {
    updateBorrowerInfo(input: $input, condition: $condition) {
      fullName
      address
      maritalStatus
      numberOfDependents
      birthdate
      ssn
      formerAddress
      phone
      presentAddressOwnership
      formerAddressOwnership
      mailingAddress
      agesOfDependents
      yrsInSchool
      noOfYrsAtPresentResidence
      noOfYrsAtFormerResidence
      email
      userToken
      clientUserId
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBorrowerInfo = /* GraphQL */ `
  mutation DeleteBorrowerInfo(
    $input: DeleteBorrowerInfoInput!
    $condition: ModelBorrowerInfoConditionInput
  ) {
    deleteBorrowerInfo(input: $input, condition: $condition) {
      fullName
      address
      maritalStatus
      numberOfDependents
      birthdate
      ssn
      formerAddress
      phone
      presentAddressOwnership
      formerAddressOwnership
      mailingAddress
      agesOfDependents
      yrsInSchool
      noOfYrsAtPresentResidence
      noOfYrsAtFormerResidence
      email
      userToken
      clientUserId
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
