// import React, { useState } from 'react';
// import { Grid, Typography, Box, TextField, Button } from '@mui/material';
// import BasicHeader from './Header';
// import Footer from './Footer';
// import './ContactUs.css';

// const ContactUs = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     subject: '',
//     message: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const { name, email, subject, message } = formData;
//     const mailtoLink = `mailto:loanswifthelp@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`Name: ${name}\nEmail: ${email}\n\nMessage:\n${message}`)}`;
//     window.location.href = mailtoLink;
//   };

//   return (
//     <>
//       <BasicHeader />
//       <div className="contact-container">
//         <Grid container spacing={4} direction="column" alignItems="center" justifyContent="center">
//           <Grid item xs={12} textAlign="center">
//             <Typography variant="h4" className="contact-title">
//               Contact Us
//             </Typography>
//             <Typography variant="body1" className="contact-intro">
//               We are here to help you with any questions or concerns you may have. Feel free to reach out to us through the contact details below or fill out the contact form and we'll get back to you as soon as possible.
//             </Typography>
//           </Grid>

//           <Grid item xs={12} md={8} className="contact-details">
//             <Box className="contact-box">
//               <Typography variant="h5" className="contact-heading">
//                 Our Office
//               </Typography>
//               <Typography variant="body1" className="contact-info">
//                 <strong>Address:</strong> 4841 90th Ave SE, Mercer Island, WA 98040
//               </Typography>
//               <Typography variant="body1" className="contact-info">
//                 <strong>Phone:</strong> (425) 628-5854
//               </Typography>
//               <Typography variant="body1" className="contact-info">
//                 <strong>Email:</strong> loanswifthelp@gmail.com
//               </Typography>
//               <Typography variant="body1" className="contact-info">
//                 <strong>Office Hours:</strong> Monday - Friday, 9:00 AM - 5:00 PM
//               </Typography>
//             </Box>
//           </Grid>

//           {/* <Grid item xs={12} md={8}>
//             <Box className="form-box">
//               <Typography variant="h5" className="form-title">
//                 Contact Form
//               </Typography>
//               <form noValidate autoComplete="off" onSubmit={handleSubmit}>
//                 <TextField
//                   fullWidth
//                   label="Name"
//                   variant="outlined"
//                   margin="normal"
//                   required
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                 />
//                 <TextField
//                   fullWidth
//                   label="Email"
//                   variant="outlined"
//                   margin="normal"
//                   required
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                 />
//                 <TextField
//                   fullWidth
//                   label="Subject"
//                   variant="outlined"
//                   margin="normal"
//                   required
//                   name="subject"
//                   value={formData.subject}
//                   onChange={handleChange}
//                 />
//                 <TextField
//                   fullWidth
//                   label="Message"
//                   variant="outlined"
//                   margin="normal"
//                   required
//                   multiline
//                   rows={4}
//                   name="message"
//                   value={formData.message}
//                   onChange={handleChange}
//                 />
//                 <Button
//                   type="submit"
//                   variant="contained"
//                   color="primary"
//                   className="submit-button"
//                 >
//                   Submit
//                 </Button>
//               </form>
//             </Box>
//           </Grid> */}
//         </Grid> 
//       </div> 
//       <Footer />
//     </>
//   );
// }

// export default ContactUs;

import React, { useState } from 'react';
import { Grid, Typography, Box, TextField, Button } from '@mui/material';
import BasicHeader from './Header';
import Footer from './Footer';
import './ContactUs.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, subject, message } = formData;
    const mailtoLink = `mailto:loanswifthelp@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`Name: ${name}\nEmail: ${email}\n\nMessage:\n${message}`)}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      <BasicHeader />
      <div className="contact-container">
        <Grid container spacing={4} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12} textAlign="center">
            <Typography variant="h4" className="contact-title">
              Contact Us
            </Typography>
            <Typography variant="body1" className="contact-intro">
              We are here to help you with any questions or concerns you may have. Feel free to reach out to us through the contact details below or fill out the contact form and we'll get back to you as soon as possible.
            </Typography>
          </Grid>

          <Grid item xs={12} md={8} className="contact-details">
            <Box className="contact-box">
              <Typography variant="h5" className="contact-heading">
                Our Office
              </Typography>
              <Typography variant="body1" className="contact-info">
                <strong>Address:</strong> 4841 90th Ave SE, Mercer Island, WA 98040
              </Typography>
              <Typography variant="body1" className="contact-info">
                <strong>Phone:</strong> (425) 628-5854
              </Typography>
              <Typography variant="body1" className="contact-info">
                <strong>Email:</strong> loanswifthelp@gmail.com
              </Typography>
              <Typography variant="body1" className="contact-info">
                <strong>Office Hours:</strong> Monday - Friday, 9:00 AM - 5:00 PM
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div> 
      <Footer />
    </>
  );
}

export default ContactUs;

