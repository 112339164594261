import React from 'react';
import './App.css';
import BasicHeader from './Header.js';
import Footer from "./Footer.js";
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';
import ceo_pic from './CEO.png';
import cto_pic from './cto.jpg';
import alisa_pic from './AlisaPicture.jpeg';
import marv_pic from './marv.jpeg';
import mission_pic from './iStock-1331964393.jpg';
import values_pic from './iStock-1134456412.jpg';
import story_pic from './iStock-1185220738.jpg';
import joinus_pic from './iStock-476729122.jpg';

Amplify.configure(awsExports);

const profiles = [
  {
    name: "Arnab Roy",
    title: "Co-Founder and CEO",
    image: ceo_pic,
    description: "Arnab is a software enthusiast with extensive experience in cloud reliability, full-stack development, and machine learning. He has worked with JPMorgan Chase, Meta, Oracle, Microsoft, and startups, excelling in collaborative environments."
  },
  {
    name: "Sayari Ghosh",
    title: "Co-Founder and CTO",
    image: cto_pic,
    description: "Sayari is a software engineer specializing in cloud migration, backend development, and performance optimization. She has worked with SAP Concur, BlackBerry, and Microsoft, excelling in dynamic environments and committed to innovation."
  },
  {
    name: "Marv Nicholson",
    title: "Senior Real Estate Advisor",
    image: marv_pic,
    description: "Marv, a lifelong Puget Sound resident, has over 20 years in real estate and loan origination. As a second-generation Managing Broker, his regional knowledge and dedication to client satisfaction make him an invaluable advisor to Gobdu."
  },
  {
    name: "Alisa Mirza",
    title: "Software Developer Intern",
    image: alisa_pic,
    description: "Alisa, an engineering student at the University of Washington, specializes in UX/UI design. She leads product strategy, website design, and market research at Gobdu, enhancing user satisfaction through thoughtful, innovative solutions."
  }
];

export default function ActionAreaCard() {
  return (
    <>
      <div>
        <BasicHeader />
      </div>

      <div className='main-container'>
        <Box sx={{ padding: 4 }}>
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>

          <Box sx={{ padding: 4 }}></Box>
          <Typography variant="h5" gutterBottom>
            Meet Our Team
          </Typography>
          <Grid container spacing={3}>
            {profiles.map((profile, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <Card sx={{ maxWidth: 345, margin: 'auto' }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="450"
                      image={profile.image}
                      alt={profile.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {profile.name}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {profile.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {profile.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: '#c6ccc6', color: '#fff', display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="200"
                  image={mission_pic}
                  alt="Our Mission & Vision"
                />
                <CardContent sx={{ flex: '1 1 auto' }}>
                  <Typography variant="h5" gutterBottom sx={{ color: '#447597' }}>
                    Our Mission & Vision
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#000' }}>
                    To make the journey of becoming a homeowner easier, faster, and hassle-free. We aim to revolutionize the mortgage application process with cutting-edge automation and exceptional customer service.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: '#c6ccc6', color: '#fff', display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="200"
                  image={values_pic}
                  alt="Our Values & Culture"
                />
                <CardContent sx={{ flex: '1 1 auto' }}>
                  <Typography variant="h5" gutterBottom sx={{ color: '#447597' }}>
                    Our Values & Culture
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#000' }}>
                    Innovation, Customer Focus, Integrity, Transparency, Collaboration. We foster a collaborative and inclusive work environment where our team thrives in a dynamic, challenging atmosphere that encourages innovation and continuous learning.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: '#c6ccc6', color: '#fff', display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="200"
                  image={story_pic}
                  alt="Our Story"
                />
                <CardContent sx={{ flex: '1 1 auto' }}>
                  <Typography variant="h5" gutterBottom sx={{ color: '#447597' }}>
                    Our Story
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#000' }}>
                    Founded in 2022, LoanSwift started with the vision to streamline the home loan process. We are currently at the MVP stage and continuously working to innovate and improve our services.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: '#c6ccc6', color: '#fff', display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="200"
                  image={joinus_pic}
                  alt="Join Us"
                />
                <CardContent sx={{ flex: '1 1 auto' }}>
                  <Typography variant="h5" gutterBottom sx={{ color: '#447597' }}>
                    Join Us
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#000' }}>
                    We believe in fostering a collaborative and inclusive work environment. Our team thrives in a dynamic, challenging atmosphere where innovation and continuous learning are encouraged.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

        <br />
        <Footer />
      </div>
    </>
  );
}
