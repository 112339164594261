import React, { useRef, useEffect, useState } from 'react';
import { Grid, Typography, Box, TextField, Button, Card, CardContent } from '@mui/material';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { createDemoRequest } from './graphql/mutations';
import BasicHeader from './Header';
import Footer from './Footer';
import video from './loanSwift1.mp4';
import audio from './success.mp3';
import './App.css';
import partner1Logo from './logo_cascadia.jpg';
import partner2Logo from './logo_exp.png';
import image1 from './iStock-1390654326.jpg';
import image2 from './iStock-1393059175.jpg';
import image3 from './iStock-905725284.jpg';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

const client = generateClient();

const AboutGobdu = () => {
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const [email, setEmail] = useState('');
  const [flipped, setFlipped] = useState([false, false, false]);

  const handleFlip = (index) => {
    setFlipped(flipped.map((f, i) => (i === index ? !f : f)));
  };

  const cardContent = [
    {
      image: image1,
      text: "Faster Loan Application Processing",
      flipText: "Automated loan application can be created and processed faster."
    },
    {
      image: image2,
      text: "Reuse application for loan or refinance",
      flipText: "No need to upload new documents for multiple loan or refinance applications now or 20 years later."
    },
    {
      image: image3,
      text: "Keep your financial documents safe",
      flipText: "Store all your financial documents safely in a single portal."
    },
  ];

  useEffect(() => {
    const video = videoRef.current;
    const audio = audioRef.current;

    if (video && audio) {
      const syncAudioWithVideo = () => {
        audio.currentTime = video.currentTime;
      };

      video.addEventListener('timeupdate', syncAudioWithVideo);

      return () => {
        video.removeEventListener('timeupdate', syncAudioWithVideo);
      };
    }
  }, []);

  const handlePlay = () => {
    videoRef.current.play();
    audioRef.current.play();
  };

  const handlePause = () => {
    videoRef.current.pause();
    audioRef.current.pause();
  };

  const handleSubmit = async () => {
    if (email) {
      try {
        const newDemoRequest = await client.graphql({
          query: createDemoRequest,
          variables: {
            input: {
              email: email,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            }
          }
        });
        alert('Email submitted successfully!');
        setEmail('');
      } catch (error) {
        console.error('Error submitting email:', error);
        alert('Error submitting email. Please try again.');
      }
    } else {
      alert('Please enter a valid email address.');
    }
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    gap: '20px',
    width: '100%',
    textAlign: 'center',
    padding: '20px',
    boxSizing: 'border-box'
  };

  const boxStyle = {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    background: '#fff',
  };

  const videoBoxStyle = {
    ...boxStyle,
    padding: '10px', // Reduce padding to make video larger
  };

  const largeImageContainerStyle = {
    width: '100%',
    height: '400px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '25px',
    marginBottom: '20px'
  };

  const overlayBoxStyle = {
    position: 'absolute',
    top: '75%',
    left: '150px', // Move to the left
    transform: 'translateY(-50%)', // Center vertically
    color: 'white',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    padding: '20px',
    borderRadius: '10px',
    width: 'auto',
    maxWidth: '60%', // Adjust width to fit within the image
    boxSizing: 'border-box'
  };

  const demoRequestBoxStyle = {
    position: 'absolute',
    bottom: '30px',
    right: '60px',
    background: '#2d3e50',
    color: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '250px',
    height: '200px',
    boxSizing: 'border-box'
  };

  return (
    <>
      <BasicHeader />
      <div className="main-container" style={{ marginTop: '70px' }}>
        <Grid container spacing={4} direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12} style={{ padding: 0, position: 'relative', width: '100%', height: '400px', overflow: 'hidden' }}>
            <Box style={largeImageContainerStyle}>
              <img src="/images/iStock-495625758 2.png" alt="New Background" style={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block' }} />
              <Box style={overlayBoxStyle}>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: 'Nunito, sans-serif',
                    fontWeight: 700,
                    color: '#fff',
                    fontSize: '24px',
                    textAlign: 'center',
                  }}
                  gutterBottom
                >
                  Effortless Homeownership. Simplified Mortgage Applications.
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: 'Nunito, sans-serif',
                    fontWeight: 500,
                    color: '#21b6ae',
                    fontSize: '20px',
                    textAlign: 'center'
                  }}
                >
                  Join Us Today and Make Homeownership a Reality.
                </Typography>
              </Box>
              <Box style={demoRequestBoxStyle}>
                <Typography variant="h5" sx={{ fontFamily: 'Nunito, sans-serif', fontWeight: 700, color: '#21b6ae', marginBottom: '20px' }}>
                  Request for Demo
                </Typography>
                <TextField
                  fullWidth
                  label="Email Address"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ marginBottom: '20px' }}
                  InputLabelProps={{
                    style: { color: 'white' }
                  }}
                  InputProps={{
                    style: { color: 'white' }
                  }}
                />
                <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid container className="grid-section" justifyContent="center" style={{ marginTop: '-20px' }}>
            <Box className="grid-item-container" style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: '20px' }}>
              {cardContent.map((content, index) => (
                <Box
                  key={index}
                  className="grid-item"
                  style={{ position: 'relative', height: '300px', width: '400px', perspective: '1000px' }}
                  onClick={() => handleFlip(index)}
                >
                  <Box className={`flip-card ${flipped[index] ? 'flipped' : ''}`} style={{ width: '100%', height: '100%', transition: 'transform 0.6s', transformStyle: 'preserve-3d', position: 'relative' }}>
                    <Box className="flip-card-front" style={{ backgroundImage: `url(${content.image})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '5px', position: 'absolute', width: '100%', height: '100%', backfaceVisibility: 'hidden' }}>
                      <Box className="overlay" style={{ position: 'absolute', top: '5px', bottom:'220px', left: '50%', transform: 'translateX(-50%)', color: 'white', background: 'rgba(0, 0, 0, 0.5)', padding: '5px 10px', borderRadius: '5px', width: '80%', textAlign: 'center' }}>
                        <Typography variant="h6" >{content.text}</Typography>
                      </Box>
                    </Box>
                    <Box className="flip-card-back" style={{ background: 'rgba(0, 0, 0, 0.7)', color: 'white', borderRadius: '5px', position: 'absolute', width: '100%', height: '100%', backfaceVisibility: 'hidden', transform: 'rotateY(180deg)', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: '20px', boxSizing: 'border-box' }}>
                      <Typography variant="h6" style={{ color: 'white' }}>{content.flipText}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>


          <Grid item xs={12} md={12} className="middle-section-wrapper">
            <Box style={containerStyle}>
              <Box style={videoBoxStyle}>
                <video
                  ref={videoRef}
                  width="100%"
                  controls
                  onPlay={handlePlay}
                  onPause={handlePause}
                  style={{ display: 'block' }}
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <audio ref={audioRef}>
                  <source src={audio} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </Box>
              <Box style={{ ...boxStyle, background: '#2d3e50', color: '#fff' }}>
                <Typography variant="h6" className="middle-text-title">
                  QuickApplyLoan: Your Co-Pilot in Home Loan Processing
                </Typography>
                <br />
                <Typography variant="body1" className="additional-text" style={{ color: '#fff' }}>
                  QuickApplyLoan simplifies your mortgage application and offers insights at every step. Join thousands of happy homeowners who trust us for their home loan needs. You're never alone on your journey with QuickApplyLoan!
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid container className="partner-section" alignItems="center" justifyContent="center" style={{ padding: '40px 20px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h5" gutterBottom>
              Our Partners
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item>
                <Card sx={{ width: '200px', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
                  <CardContent>
                    <img src={partner1Logo} alt="Partner 1" style={{ maxWidth: '150px', maxHeight: '150px' }} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card sx={{ width: '200px', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
                  <CardContent>
                    <img src={partner2Logo} alt="Partner 2" style={{ maxWidth: '150px', maxHeight: '150px' }} />
                  </CardContent>
                </Card>
              </Grid>
              {/* Add more partner logos as needed */}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default AboutGobdu;
